import React from "react";

import { SiteName } from "../../config/index.js";

export default class Foot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="container foot">
        © 2021 - 2021
        <a target="_blank" rel="noopener noreferrer" href="https://github.com/xusenlin/hzzm">{SiteName}</a>
        Powered By
        <a target="_blank" rel="noopener noreferrer" href="https://github.com/chinese-poetry/chinese-poetry">
          ChinesePoetry
        </a>
        <a target="_blank" rel="noopener noreferrer" href="https://beian.miit.gov.cn/">
          津ICP备14005464号-5
        </a>
        <a target="_blank" rel="noopener noreferrer" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=12010402001541">
          津公网安备 12010402001541号
        </a>
      </div>
    );
  }
}
